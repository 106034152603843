import React, { useState } from "react";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import validator from "validator";

import MuiAlert from "@mui/material/Alert";

import PamgroEmailContent from "./PamgroEmailContent";
import { renderToString } from "react-dom/server";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
} from "@mui/material";
import PdfDocument from "./PamgroPDF";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import Item from "./Item";
import StepperHorizontal from "../src/images/StepperHorizontal.svg";
import step1 from "../src/images/step1.svg";
import { Stack } from "@mui/system";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NetPayCalculator() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "success">(
    "success"
  );
  const [dailyRate, setDailyRate] = useState<number>(0);
  const [pension, setPension] = useState<"Opt in" | "Opt out">("Opt out");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [optInValue, setOptInValue] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const handleUnlockTruePayrollClick = () => {
    if (!dailyRate || dailyRate < 190) {
      openSnackbar("Daily rate should not be less than 190", "error");
    } else if (pension === "Opt in" && (optInValue > 97 || optInValue < 5)) {
      openSnackbar("Opt In Value should be between 5 to 97", "error");
    } else {
      setCurrentStep(2);
    }
  };

  const calculateNetPay = (dailyRateValue: number, pension: string) => {
    const numberOfDays = 252;
    const daysPerMonth = 21;
    const yearlyMonths = 12;
    const salarySacrifice = 0;

    const pensionContribution = pension === "Opt in" ? 0.03 : 0;
    const expandPension = pension === "Opt in" ? optInValue / 100 : 0;

    const billedAmountYearly = numberOfDays * dailyRateValue;
    const billedAmountMonthly = daysPerMonth * dailyRateValue;

    const sippContributionYearly = salarySacrifice;
    const sippContributionMonthly = salarySacrifice;

    const ourFeesMonthly = salarySacrifice > 0 ? 130 : 70;
    const ourFeesYearly = ourFeesMonthly * 12;

    var amountDistributedFPYearly =
      billedAmountYearly - sippContributionYearly - ourFeesYearly;
    var amountDistributedFPMonthly =
      billedAmountMonthly - sippContributionMonthly - ourFeesMonthly;

    const grossPaySOMonthly =
      (amountDistributedFPMonthly + 758 * 0.138) /
      (1 + 0.005 + 0.138 + pensionContribution);
    const grossPaySOYearly = grossPaySOMonthly * 12;

    let pensionContributionYearly = 0;
    let pensionContributionMonthly = 0;
    if (pension === "Opt in") {
      pensionContributionMonthly = grossPaySOMonthly * 0.03;
      pensionContributionYearly = pensionContributionMonthly * 12;
    }
    const employerNICMonthly = ((grossPaySOMonthly - 758) * 10) / 100;
    const employerNICYearly = employerNICMonthly * 12;

    const dailyGrossRateYearly = grossPaySOYearly / numberOfDays;
    const dailyGrossRateMonthly = grossPaySOMonthly / daysPerMonth;

    const grossPayYearly = grossPaySOYearly;
    const grossPayMonthly = grossPaySOMonthly;

    const apprenticeshipLevyMonthly = grossPaySOMonthly * 0.005;
    const apprenticeshipLevyYearly = apprenticeshipLevyMonthly * 12;

    const expandPensionYearly = grossPayYearly * expandPension;

    const expandPensionMonthly = expandPensionYearly / 12;

    const taxablePayAPYearly =
      expandPensionYearly > 60000
        ? grossPayYearly - 60000
        : grossPayYearly * (1 - expandPension);

    const taxablePayAPMonthly = taxablePayAPYearly / 12;

    const xTaxFreeAllowYearly = 12570;

    const xTaxFreeAllowMonthly = xTaxFreeAllowYearly / 12;

    const totalTaxableYearly =
      taxablePayAPYearly < xTaxFreeAllowYearly
        ? 0
        : taxablePayAPYearly - xTaxFreeAllowYearly;
    const totalTaxableMonthly = totalTaxableYearly / 12;

    const _20_0_RateYearly =
      totalTaxableYearly > 0
        ? totalTaxableYearly > 37700
          ? 37700 * 0.2
          : totalTaxableYearly * 0.2
        : 0;
    const _20_0_RateMonthly = _20_0_RateYearly / 12;

    const _40_0_RateYearly =
      totalTaxableYearly <= 37700
        ? 0
        : totalTaxableYearly <= 125140
        ? (totalTaxableYearly - 37700) * 0.4
        : (totalTaxableYearly - (totalTaxableYearly - 125140) - 37700) * 0.4;
    const _40_0_RateMonthly = _40_0_RateYearly / 12;

    const _45_0_RateYearly =
      taxablePayAPYearly > 125140 ? (taxablePayAPYearly - 125140) * 0.45 : 0;
    const _45_0_RateMonthly = _45_0_RateYearly / 12;

    const xTotalTaxDueYearly =
      _20_0_RateYearly + _40_0_RateYearly + _45_0_RateYearly;

    const xTotalTaxDueMonthly =
      _20_0_RateMonthly + _40_0_RateMonthly + _45_0_RateMonthly;

    const nationalInsuranceMonthly =
      grossPayMonthly < 1048
        ? 0
        : grossPayMonthly <= 4189
        ? (grossPayMonthly - 1048) * 0.1
        : (4189 - 1048) * 0.1 + (grossPayMonthly - 4189) * 0.02;
    const nationalInsuranceYearly = nationalInsuranceMonthly * 12;

    const totalDeductionsYearly =
      _20_0_RateYearly +
      _40_0_RateYearly +
      _45_0_RateYearly +
      nationalInsuranceYearly +
      expandPensionYearly;
    const totalDeductionsMonthly =
      _20_0_RateMonthly +
      _40_0_RateMonthly +
      _45_0_RateMonthly +
      nationalInsuranceMonthly +
      expandPensionMonthly;

    const netWageYearly = grossPayYearly - totalDeductionsYearly;
    const netWageMonthly = grossPayMonthly - totalDeductionsMonthly;

    const totalAmountRetainedYearly = netWageYearly;
    const totalAmountRetainedMonthly = netWageMonthly;

    const dailyNetRateYearly = totalAmountRetainedYearly / numberOfDays;
    const dailyNetRateMonthly = totalAmountRetainedMonthly / daysPerMonth;

    return {
      yearly: {
        "Contracted Rate £": parseFloat(dailyRateValue.toFixed(2)),
        "Invoice Value": parseFloat(billedAmountYearly.toFixed(2)),
        "Avance Margin (-)": parseFloat(ourFeesYearly.toFixed(2)),
        "Employer national insurance (-) ": parseFloat(
          employerNICYearly.toFixed(2)
        ),
        "Apprenticeship levy(-)": parseFloat(
          apprenticeshipLevyYearly.toFixed(2)
        ),
        "Employer’s pension contributions (-)": parseFloat(
          pensionContributionYearly.toFixed(2)
        ),
        "Gross pay": parseFloat(grossPayYearly.toFixed(2)),
        "Daily Gross Rate": parseFloat(dailyGrossRateYearly.toFixed(2)),
        "Total taxable": parseFloat(totalTaxableYearly.toFixed(2)),
        "xTotal Tax due": parseFloat(xTotalTaxDueYearly.toFixed(2)),
        "Employee National Insurance": parseFloat(
          nationalInsuranceYearly.toFixed(2)
        ),
        "expandPension [you]": parseFloat(expandPensionYearly.toFixed(2)),
        "Net Income after tax": parseFloat(netWageYearly.toFixed(2)),
        "Per day net in hand £": parseFloat(dailyNetRateYearly.toFixed(2)),
      },
      monthly: {
        "Contracted Rate £": parseFloat(dailyRateValue.toFixed(2)),
        "Invoice Value": parseFloat(billedAmountMonthly.toFixed(2)),
        "Avance Margin (-)": parseFloat(ourFeesMonthly.toFixed(2)),
        "Employer national insurance (-) ": parseFloat(
          employerNICMonthly.toFixed(2)
        ),
        "Apprenticeship levy(-)": parseFloat(
          apprenticeshipLevyMonthly.toFixed(2)
        ),
        "Employer’s pension contributions (-)": parseFloat(
          pensionContributionMonthly.toFixed(2)
        ),
        "Gross pay": parseFloat(grossPayMonthly.toFixed(2)),
        "Daily Gross Rate": parseFloat(dailyGrossRateMonthly.toFixed(2)),
        "Total taxable": parseFloat(totalTaxableMonthly.toFixed(2)),
        "xTotal Tax due": parseFloat(xTotalTaxDueMonthly.toFixed(2)),
        "Employee National Insurance": parseFloat(
          nationalInsuranceMonthly.toFixed(2)
        ),
        "expandPension [you]": parseFloat(expandPensionMonthly.toFixed(2)),
        "Net Income after tax": parseFloat(netWageMonthly.toFixed(2)),
        "Per day net in hand £": parseFloat(dailyNetRateMonthly.toFixed(2)),
      },
    };
  };
  type Result = {
    yearly: Record<string, number>;
    monthly: Record<string, number>;
  };

  const pensionOptions = ["Opt in", "Opt out"];
  const openSnackbar = (message: string, severity: "error" | "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const pensionVal = pension === "Opt in" ? optInValue : 0;

  // const [calculationResults, setCalculationResults] = useState<any | null>(
  //   null
  // );

  // const handleCalculate = (finalRes: any) => {
  //   const results = calculateNetPay(dailyRate, pension);
  //   setCalculationResults(results);

  //   window.open(
  //     `http://ss.myhost.com:3006/ukpayroll?dailypayrate=${dailyRate}&monthly=${JSON.stringify(
  //       finalRes.monthly
  //     )}&pensionOpt=${pension}&pensionVal=${pensionVal}`,
  //     "_blank"
  //   );
  // };

  const handleGeneratePDFClick = async () => {
    if (name.trim() === "") {
      openSnackbar("Please fill in your name.", "error");
    } else if (!validator.isEmail(email)) {
      openSnackbar("Invalid email address.", "error");
    } else {
      openSnackbar(
        "Please check your email box. We have sent your Payroll Details.",
        "success"
      );
      var finalRes: Result;
      finalRes = calculateNetPay(dailyRate, pension);

      const emailBodyContent = renderToString(
        <PamgroEmailContent
          pdfDownloadLink={""}
          candidateName={name}
          url={`https://calc.pamgro.com/ukpayroll?dailypayrate=${dailyRate}&monthly=${JSON.stringify(
            finalRes.monthly
          )}&pensionOpt=${pension}&pensionVal=${pensionVal}`}
        />
      );
      const emailData = {
        candidateName: name,
        candidateNameMobileNo: phone,
        candidateEmail: email,
        dailyRate: dailyRate,
        pensionContribution: pensionVal,
        attachmentUrl: "",
        emailBodyContent: emailBodyContent,
        calculatorReigon: "UK",
      };
      try {
        const sendEmailRes = await axios.post(
          "https://backend.pamgro.com/api/send-email",
          emailData
        );

        const saveLeadResponse = await axios.post(
          "https://backend.pamgro.com/api/saveLead",
          emailData
        );

        if (sendEmailRes.status === 200 && saveLeadResponse.status === 201) {
          // @ts-ignore
          window.dataLayer.push({
            event: "UKCalculatorSubmitClick",
            eventCategory: "Payroll Details",
            eventAction: "Sumbit Button Clicked",
            eventLabel: "Submit Payroll Details UK",
          });

          window.parent.location.href = "https://pamgro.com/thankyou-UK/";
        }
      } catch (error) {
        window.parent.location.href = "https://pamgro.com/thankyou-UK/";
        console.log("Error during the process: ", error);
      }
    }
  };

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const width = window.innerWidth;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff00",
        height: "50vh",
        paddingLeft: "19px",
      }}
    >
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <Typography>{popupMessage}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowPopup(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
            style={{
              backgroundColor: snackbarSeverity === "error" ? "red" : "green",
              color: "white",
            }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        <Grid
          container
          direction="column"
          mt={12}
          xs={1.8}
          sm={3}
          md={2}
          lg={3}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-4vh",
          }}
        >
          {currentStep === 1 && (
            <Item>
              <Grid container>
                {!isMobileDevice() ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ marginTop: "-3vh" }}
                    >
                      <Item sx={{ padding: isMobileDevice() ? 0 : 2 }}>
                        <Grid item xs={4} sm={8}>
                          <Typography
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              fontStyle: "normal",
                              fontFamily: "'Gilroy Medium', sans-serif",
                              fontSize: "24px",
                              textAlign: "start",
                              marginLeft: "1vh",
                              marginTop: "1vh",
                            }}
                          >
                            Add pay details
                          </Typography>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ flexBasis: "10.2%" }}
                    >
                      <img
                        src={step1}
                        alt="step 1"
                        style={{ height: "40px", width: "430px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Stack>
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "'Gilroy Medium', sans-serif",
                        fontSize: "20px",
                        textAlign: "start",
                        marginLeft: "1vh",
                        marginTop: "3vh",
                        padding: "0px",
                      }}
                    >
                      Add pay details
                    </Typography>
                    <img
                      src={step1}
                      alt="step 1"
                      style={{
                        height: "40px",
                        width: "150px",
                        marginLeft: "20vh",
                      }}
                    />
                  </Stack>
                )}
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={4.5}
                  sm={11}
                  md={10}
                  lg={10}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderBottom: "1px solid #EFEFEF",
                    marginLeft: "1vh",
                    marginTop: "1vh",
                    height: "0px",
                  }}
                ></Grid>
                <Item>
                  <Grid
                    container
                    item
                    xs={5}
                    sm={12}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      marginTop: "-2vh",
                      justifyContent: "center",
                      width: "702px",
                      height: "267px",
                      paddingTop: "8vh",
                      paddingBottom: "5vh",
                      marginLeft: "-2.8vh",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid item xs={9} sm={10.5}>
                      <InputLabel>Contracted Rate £ (£)</InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "55px",
                            },
                          }}
                          fullWidth
                          type="number"
                          value={dailyRate === 0 ? "" : dailyRate}
                          onChange={(e) => setDailyRate(Number(e.target.value))}
                          style={{ marginBottom: "1rem" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={4.5} sm={5.3} sx={{ marginTop: "2vh" }}>
                        <FormControl fullWidth>
                          <div style={{ marginBottom: "0.8rem" }}>
                            <InputLabel
                              sx={{
                                fontSize: "1.4rem",
                                marginLeft: "-1vh",
                              }}
                            >
                              Pension
                            </InputLabel>
                          </div>
                          <Select
                            style={{ borderRadius: "10px" }}
                            value={pension}
                            onChange={(e) => {
                              setPension(
                                e.target.value as "Opt in" | "Opt out"
                              );
                              setOptInValue(0);
                            }}
                          >
                            {pensionOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4.5} sm={5.3} sx={{ marginTop: "2.5vh" }}>
                        {pension === "Opt in" && (
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                style: {
                                  borderRadius: "10px",
                                  height: "55px",
                                  marginTop: "1.5vh",
                                },
                              }}
                              type="number"
                              placeholder="Min 5% (Employer contribution is fixed at 3%)"
                              value={optInValue === 0 ? "" : optInValue}
                              onChange={(e) => {
                                setOptInValue(Number(e.target.value));
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
                <Grid
                  container
                  item
                  xs={12}
                  sm={11}
                  sx={{ flexBasis: "40.2%" }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      height: "60px",
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
                      marginTop: "-3vh",
                      backgroundColor: "#EE4037",
                      borderRadius: "6px",
                      color: "#ffffff",
                      marginLeft: "3vh",
                      fontWeight: "700",
                    }}
                    onClick={handleUnlockTruePayrollClick}
                  >
                    Unlock True Payroll
                  </Button>
                </Grid>
              </Grid>
            </Item>
          )}
          {currentStep === 2 && (
            <Item>
              <Grid container>
                {!isMobileDevice() ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ marginTop: "-3vh" }}
                    >
                      <Item sx={{ padding: isMobileDevice() ? 0 : 2 }}>
                        <Grid item xs={4} sm={8}>
                          <Typography
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              fontStyle: "normal",
                              fontFamily: "'Gilroy Medium', sans-serif",
                              fontSize: "24px",
                              textAlign: "start",
                              marginLeft: "1vh",
                              marginTop: "1vh",
                            }}
                          >
                            Add pay details
                          </Typography>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ flexBasis: "10.2%" }}
                    >
                      <img
                        src={step1}
                        alt="step 1"
                        style={{ height: "40px", width: "430px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Stack>
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "'Gilroy Medium', sans-serif",
                        fontSize: "20px",
                        textAlign: "start",
                        marginLeft: "1vh",
                        marginTop: "3vh",
                        padding: "0px",
                      }}
                    >
                      Add Your details
                    </Typography>
                    <img
                      src={StepperHorizontal}
                      alt="step 1"
                      style={{
                        height: "40px",
                        width: "150px",
                        marginLeft: "20vh",
                      }}
                    />
                  </Stack>
                )}
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={4.5}
                  sm={11}
                  md={10}
                  lg={10}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderBottom: "1px solid #EFEFEF",
                    marginLeft: "1vh",
                    marginTop: "1vh",
                    height: "0px",
                  }}
                ></Grid>
                <Item>
                  <Grid
                    container
                    item
                    xs={5}
                    sm={12}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      marginTop: "-2vh",
                      justifyContent: "center",
                      width: "702px",
                      height: "267px",
                      paddingTop: "8vh",
                      paddingBottom: "5vh",
                      marginLeft: "-2.8vh",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid item xs={9} sm={10.5}>
                      <InputLabel>Name *</InputLabel>
                      <TextField
                        fullWidth
                        label="Your name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                            marginBottom: "1rem",
                            height: "55px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={4.5} sm={5.3}>
                        <InputLabel
                          sx={{
                            fontSize: "1rem",
                          }}
                        >
                          Email *
                        </InputLabel>
                        <TextField
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "55px",
                            },
                          }}
                          fullWidth
                          label="Your Email"
                          value={email}
                          onChange={(e) => {
                            const newEmail = e.target.value;
                            setEmail(newEmail);
                            setIsEmailValid(validator.isEmail(newEmail));
                          }}
                          error={!isEmailValid}
                          helperText={
                            !isEmailValid ? "Invalid email format" : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={4.5} sm={5.3}>
                        <InputLabel
                          sx={{
                            fontSize: "1rem",
                          }}
                        >
                          Phone
                        </InputLabel>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "55px",
                            },
                          }}
                          label="Your Phone no"
                          type="tel"
                          value={phone}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setPhone(numericValue);
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            maxLength: 10,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid container item xs={5} sm={11} sx={{ flexBasis: "39%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    height: "60px",
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
                    marginTop: "-3vh",
                    backgroundColor: "#EE4037",
                    borderRadius: "6px",
                    color: "#ffffff",
                    marginLeft: "3vh",
                    fontWeight: "700",
                  }}
                  onClick={handleGeneratePDFClick}
                >
                  Calculate Take-home
                </Button>
              </Grid>
            </Item>
          )}
        </Grid>
      </Container>
    </div>
  );
}
export default NetPayCalculator;
