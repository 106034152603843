export const screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
export const ismobile = screenWidth > 600;

export const backgroundBox = {
  backgroundColor: "#D5D7BF",
  width: "1791px",
  height: "240px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
} as const;

export const headerStyle = {
  color: "#141414",
  fontFamily: "Gilroy-Medium",
  fontSize: "28px",
  fontStyle: "normal",
  textAlign: "center",
  padding: "6px 0",
  margin: "-58px 0 0",
} as const;

export const headText = {
  color: "#141414",
  fontFamily: "Gilroy-Medium",
  fontSize: "16px",
  fontStyle: "normal",
  textAlign: "center",
  margin: "0",
} as const;

export const grossSalaryText = {
  color: "#000",
  textAlign: "center",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "18px",
  margin: "10px 0",
  fontWeight: "Bold",
} as const;
export const grossSalaryTex1 = {
  color: "rgb(147 144 144)",
  textAlign: "center",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "18px",
  margin: "10px 0",
  fontWeight: "Bold",
} as const;

export const verticalLineContainer = {
  textAlign: "center",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
} as const;

export const verticalLine = {
  backgroundColor: "#BABABA",
  margin: "21px auto 0",
  width: "1.5px",
  height: "15vh",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
} as const;

export const verticalLine1 = {
  backgroundColor: "#5A5A5A",
  margin: "1px auto 0",
  width: "3px",
  height: "50px",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
} as const;

export const verticalLine2 = {
  backgroundColor: "#5A5A5A",
  margin: "13px auto 0",
  width: "0.5px",
  height: "15vh",
  position: "fixed",
  left: "75.5%",
  transform: "translateX(-50%)",
} as const;

export const subTextStyling = {
  color: "#646464",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  margin: "0 0 0 0",
  fontWeight: 6,
} as const;

export const textStyling = {
  color: "#00A94A",
  margin: "30px auto 0",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "50px",
  fontStyle: "normal",
  fontWeight: 600,
  flex: 1,
} as const;

export const salarySummaryBox = {
  borderRadius: "12.1px",
  width: "755px",
  height: "200px",
  background: "#FFF",
  boxShadow: "0px 4.4px 28.6px 0px rgba(0, 0, 0, 0.09)",
  margin: "-69px 73px -69px -6px",
} as const;

export const salarySummaryBox1 = {
  borderRadius: "12.1px",
  width: "100%",
  height: "110px",
  background: "#FFF",
  boxShadow: "0px 4.4px 28.6px 0px rgba(0, 0, 0, 0.09)",
  margin: "-69px 0",
  position: "relative",
} as const;

export const salaryAnalyticsContent = {
  borderRadius: "12.1px",
  width: "1791px",
  height: "4250px",
  background: "#FFF",
  boxShadow: "0px 4.4px 28.6px 0px rgba(0, 0, 0, 0.09)",
  margin: "99px 0",
  padding: "10px",
} as const;

export const payrollCalculatorText = {
  color: "#000",
  textAlign: "center",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "30px",
  padding: "10px",
} as const;

export const rectangleStyle = {
  border: "1.1px solid #C3C3C3",
  width: "85%",
  height: "260px",
  borderRadius: "5.5px",
  margin: "auto",
  padding: "10px",
  backgroundColor: "#FAFAFA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
} as const;

export const payDetailsText = {
  color: "#000",
  fontSize: "20px",
  fontFamily: "Gilroy-SemiBold",
  marginBottom: "10px",
  margin: "18px 39px 0",
} as const;

export const dateTimeText = {
  color: "#A0A0A0",
  textAlign: "right",
  fontFamily: "Inter",
  margin: "18px 0px 0px 990px",
} as const;

export const placeholderStyle = {
  borderBottom: "1.1px solid #C3C3C3",
  marginBottom: "10px",
  color: "#222",
  fontFamily: "Inter",
  fontSize: "22px",
  fontStyle: "normal",
  opacity: "0.6",
} as const;

export const labelStyle = {
  color: "#222",
  fontFamily: "Inter",
  fontSize: "22px",
  fontStyle: "normal",
  marginBottom: "25px",
} as const;

export const middleLine = {
  borderLeft: "2px solid #BABABA",
  height: "62%",
  margin: "80px auto",
  position: "absolute",
  left: "50%",
  width: "1.5px",
  transform: "translateX(-50%)",
} as const;

export const salaryCalculationText = {
  color: "#000",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "30px",
  margin: "40px 0px 0px 300px",
} as const;

export const salaryDistributionText = {
  color: "#000",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "30px",
  marginLeft: "25vh",
  paddingRight: "17vh",
  alignContent: "top",
} as const;
export const maritalStatus = {
  color: "#000",
  fontFamily: "Gilroy-SemiBold",
  fontSize: "30px",
  position: "absolute",
  fontWeight: "bolder",
  paddingTop: "2vh",
  left: "40%",
} as const;

export const salarySummaryBoxGross = {
  borderRadius: "13.31px",
  border: "1.21px solid #5A5A5A",
  width: "700px",
  height: "185px",
  marginTop: "35px",
  marginLeft: ismobile ? "301px" : "520px",
  background: "#F7F7F7",
  padding: "20px",
} as const;

export const salarySummaryBoxTakeHome = {
  borderRadius: "13.31px",
  border: "1.21px solid #5A5A5A",
  width: "700px",
  height: "185px",
  marginTop: "60px",
  marginLeft: ismobile ? "301px" : "520px",
  background: "#FBFBEB",
  padding: "20px",
} as const;

export const textStylingGross = {
  color: "#222",
  margin: "30px auto 0",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "50px",
  fontStyle: "normal",
  fontWeight: 600,
  flex: 1,
} as const;
export const textStylingGross1 = {
  color: "#222",
  margin: "30px auto 0",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "50px",
  fontStyle: "normal",
  fontWeight: 600,
  borderRight: "1px solid gray",
  paddingRight: "2vh",
  flex: 1,
} as const;

export const subTextStylingGross = {
  color: "#222",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "17px",
  fontStyle: "normal",
  margin: "0 0 0 0",
  fontWeight: 6,
} as const;
