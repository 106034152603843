import React from "react";
import CalculatorRoutes from "./routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import NetPayCalculator from "./NetPayCalculator";

function App() {
  return (
    
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={< NetPayCalculator/>} />
        <Route path="/*" element={< CalculatorRoutes/>} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
