import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import CzechCalculator from "./CzechCalculator"; // Import your calculator components accordingly
import DutchCalculator from "./DutchCalculator";
import SwissCalculator from "./SwissCalculator";
import PolandCalculator from "./PolandCalculator";
import SwedenCalculator from "./SwedenCalculator";
import GermanCalculator from "./GermanCalculator";
import NetPayCalculator from "./NetPayCalculator";
import Germany from "./images/Germany.svg";
import Uk from "./images/Uk.svg";
import Netherlands from "./images/Netherlands.svg";
import poland from "./images/poland.svg";
import Sweden from "./images/Sweden.svg";
import czech from "./images/czech.svg";
import Switzerland from "./images/Switzerland.svg";
import Aus from "./images/Aus.svg";
import AustraliaCalculator from "./AustraliaCalculator";

const CountrySelector: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    "United Kingdom"
  );

  const handleCountryClick = (country: string) => {
    setSelectedCountry((prevCountry) =>
      prevCountry === country ? null : country
    );
  };
  const width = window.innerWidth;
  useEffect(() => {}, [selectedCountry]);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const calculateContainerDimensions = () => {
    switch (selectedCountry) {
      case "Poland":
      case "Czech":
      case "Switzerland":
      case "Australia":
        return { width: "820px", height: isMobileDevice() ? "700px" : "640px" };
      default:
        return { width: "820px", height: isMobileDevice() ? "750px" : "700px" };
    }
  };

  const containerDimensions = calculateContainerDimensions();

  return (
    <Container
      sx={{
        boxShadow: "0px 4px 26px 0px rgba(0, 0, 0, 0.09)",
        justifyContent: "flex-start",
        width: width,
        height: containerDimensions.height,
      }}
    >
      <Typography
        sx={{
          color: "#9E9E9E",
          fontFamily: "Inter",
          fontWeight: "500",
          fontSize: "16px",
          marginLeft: "6vh",
          marginBottom: "-1vh",
          paddingTop: "2vh",
        }}
      >
        {" "}
        Country
      </Typography>
      {width < 576 ? (
        <Grid
          container
          spacing={0.5}
          sx={{
            //  display: "flex",
            justifyContent: "left",
            marginTop: "2vh",
          }}
        >
          {/* First Line */}
          <Grid item xs={5} sm={1.5} md={0.3} lg={0.3} sx={{ flexBasis: "5%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("United Kingdom")}
              size="small"
              style={{
                color:
                  selectedCountry === "United Kingdom" ? "#222222" : "#444444",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px 16px",
                borderRadius: "6px",
                fontFamily: "Inter",
                height: "42px",

                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "United Kingdom"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "8px" }}>
                <img
                  src={Uk}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />
              </span>
              UK
            </Button>
          </Grid>

          <Grid item xs={5} sm={1.8} sx={{ flexBasis: "5%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Sweden")}
              size="small"
              style={{
                color: "#000",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                height: "42px",

                padding: "2px 16px",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Sweden"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                {" "}
                <img
                  src={Sweden}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />{" "}
              </span>
              Sweden
            </Button>
          </Grid>
          <Grid item xs={5} sm={1.8} sx={{ flexBasis: "5%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Czech")}
              size="small"
              style={{
                color: "#000",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 16px",
                height: "42px",
                width: "227.33",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Czech"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                <img
                  src={czech}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />{" "}
              </span>
              Czech
            </Button>
          </Grid>

          <Grid item xs={5} sm={1.8} sx={{ flexBasis: "5%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Netherlands")}
              size="small"
              style={{
                color:
                  selectedCountry === "Netherlands" ? "#222222" : "#444444",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 16px",
                height: "42px",

                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Netherlands"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                <img
                  src={Netherlands}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />
              </span>
              Netherlands
            </Button>
          </Grid>

          <Grid item xs={5} sm={1.8} sx={{ flexBasis: "5%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Germany")}
              size="small"
              style={{
                color: selectedCountry === "Germany" ? "#222222" : "#444444",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                height: "42px",
                width: "140.33",
                padding: "2px 16px",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Germany"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                {" "}
                <img
                  src={Germany}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />
              </span>
              Germany
            </Button>
          </Grid>
          {/* </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "left",
          marginTop: "1vh",
        }}
      > */}
          {/* Second Line */}

          <Grid item xs={5} sm={2.3} sx={{ flexBasis: "22%" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Switzerland")}
              size="small"
              style={{
                color: "#000",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 16px",
                height: "42px",
                width: "227.33",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Switzerland"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                {" "}
                <img
                  src={Switzerland}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />{" "}
              </span>
              Switzerland
            </Button>
          </Grid>

          <Grid item xs={3.8} sm={1.88}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Poland")}
              size="small"
              style={{
                color: "#000",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 16px",
                height: "42px",
                width: "227.33",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Poland"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                {" "}
                <img
                  src={poland}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />{" "}
              </span>
              Poland
            </Button>
          </Grid>
          <Grid item xs={5} sm={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCountryClick("Australia")}
              size="small"
              style={{
                color: "#000",
                textTransform: "none",
                fontWeight: "400",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 16px",
                height: "42px",
                width: "227.33",
                borderRadius: "6px",
                fontFamily: "Inter",
                transition: "transform 0.3s, background 0.3s, color 0.3s",
                border:
                  selectedCountry === "Australia"
                    ? "1px solid #7A7A7A"
                    : "1.4px solid #E4E4E4",
              }}
            >
              <span style={{ color: "#000", marginRight: "10px" }}>
                {" "}
                <img
                  src={Aus}
                  alt=""
                  style={{ height: "22px", marginTop: "0.7vh" }}
                />{" "}
              </span>
              Australia
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            spacing={5}
            sx={{
              display: "flex",
              justifyContent: "left",
              marginTop: "2vh",
              marginLeft: "5vh",
            }}
          >
            {/* First Line */}
            <Grid item xs={6} sm={7} sx={{ flexBasis: "27%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("United Kingdom")}
                size="small"
                style={{
                  color:
                    selectedCountry === "United Kingdom"
                      ? "#222222"
                      : "#444444",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "10px 16px",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  height: "42px",
                  width: "227.33",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "United Kingdom"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "8px" }}>
                  <img
                    src={Uk}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />
                </span>
                The United Kingdom
              </Button>
            </Grid>
            <Grid item xs={5} sm={4} sx={{ flexBasis: "20.5%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Netherlands")}
                size="small"
                style={{
                  color:
                    selectedCountry === "Netherlands" ? "#222222" : "#444444",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "42px",
                  width: "156.33",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Netherlands"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  <img
                    src={Netherlands}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />
                </span>
                Netherlands
              </Button>
            </Grid>
            <Grid item xs={5} sm={3} sx={{ flexBasis: "17.2%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Sweden")}
                size="small"
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "42px",
                  width: "132.33",
                  padding: "2px 16px",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Sweden"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  {" "}
                  <img
                    src={Sweden}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />{" "}
                </span>
                Sweden
              </Button>
            </Grid>
            <Grid item xs={5} sm={2} sx={{ flexBasis: "20.5%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Germany")}
                size="small"
                style={{
                  color: selectedCountry === "Germany" ? "#222222" : "#444444",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "42px",
                  width: "140.33",
                  padding: "2px 16px",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Germany"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  {" "}
                  <img
                    src={Germany}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />
                </span>
                Germany
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "left",
              marginTop: "1vh",
              marginLeft: "5vh",
            }}
          >
            {/* Second Line */}

            <Grid item xs={5} sm={1.8} sx={{ flexBasis: "5%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Czech")}
                size="small"
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "42px",
                  width: "227.33",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Czech"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  <img
                    src={czech}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />{" "}
                </span>
                Czech
              </Button>
            </Grid>
            <Grid item xs={5} sm={2.3} sx={{ flexBasis: "22%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Switzerland")}
                size="small"
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "42px",
                  width: "227.33",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Switzerland"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  {" "}
                  <img
                    src={Switzerland}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />{" "}
                </span>
                Switzerland
              </Button>
            </Grid>

            <Grid item xs={5} sm={1.88}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Poland")}
                size="small"
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "42px",
                  width: "227.33",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Poland"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  {" "}
                  <img
                    src={poland}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />{" "}
                </span>
                Poland
              </Button>
            </Grid>
            <Grid item xs={5} sm={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCountryClick("Australia")}
                size="small"
                style={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "400",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "42px",
                  width: "227.33",
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  transition: "transform 0.3s, background 0.3s, color 0.3s",
                  border:
                    selectedCountry === "Australia"
                      ? "1px solid #7A7A7A"
                      : "1.4px solid #E4E4E4",
                }}
              >
                <span style={{ color: "#000", marginRight: "10px" }}>
                  {" "}
                  <img
                    src={Aus}
                    alt=""
                    style={{ height: "22px", marginTop: "0.7vh" }}
                  />{" "}
                </span>
                Australia
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Grid
        container
        xs={5}
        sx={{ justifyContent: "left", marginLeft: "-6vh", marginTop: "3vh" }}
      >
        {selectedCountry === "Czech" && <CzechCalculator />}
        {selectedCountry === "United Kingdom" && <NetPayCalculator />}
        {selectedCountry === "Poland" && <PolandCalculator />}
        {selectedCountry === "Switzerland" && <SwissCalculator />}
        {selectedCountry === "Germany" && <GermanCalculator />}
        {selectedCountry === "Netherlands" && <DutchCalculator />}
        {selectedCountry === "Sweden" && <SwedenCalculator />}
        {selectedCountry === "Australia" && <AustraliaCalculator />}
      </Grid>
    </Container>
  );
};

export default CountrySelector;
