import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const PolandPieChart = ({ data, ismobile }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myPieChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          "Net Salary",
          "Insurance",
          "Total Employee Contributuion",
          "Basis For Helath Insurance Premium ",
          "Monthly Income Cost",
          "Basis For Taxation",
          "Tax Free Allowance",
          "Health Premium Collected",
          "Tax Due(paid) To The Office",
        ],
        datasets: [
          {
            data: data,
            backgroundColor: [
              "#00A94A",
              "#FACC15",
              "#9063F1",
              "#F59E0B",
              "#EC4899",
              "#5A94F2",
              "#14B8A6",
              "#3652AD",
              "#99BC85",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: ismobile ? "right" : "bottom", // Set legend position to 'right'
          },
        },
        aspectRatio: ismobile ? 1 : 2,
      },
    });

    return () => {
      myPieChart.destroy();
    };
  }, [data, ismobile]);

  return <canvas ref={chartRef} />;
};

export default PolandPieChart;
