import React from "react";
import { Route, Routes } from "react-router-dom";

import NetPayCalculator from "../NetPayCalculator";
import CzechCalculator from "../CzechCalculator";
import GermanCalculator from "../GermanCalculator";
import PolandCalculator from "../PolandCalculator";
import SwedenCalculator from "../SwedenCalculator";
import SwissCalculator from "../SwissCalculator";
import DutchCalculator from "../DutchCalculator";
import CountrySelector from "../countrySelection";
import PolandPDF from "../PolandPDF";
import Czechpdf from "../CzechPdf";
import SwissPDF from "../SwissPDF";
import PamgroPDF from "../PamgroPDF";
import { AustraliaPDF } from "../AustaliaPdf";
const CalculatorRoutes = () => {
  return (
    <Routes>
      <Route path="/countrySelection" element={<CountrySelector />} />
      <Route path="/uk" element={<NetPayCalculator />} />
      <Route path="/cz" element={<CzechCalculator />} />
      <Route path="/swed" element={<SwedenCalculator />} />
      <Route path="/ger" element={<GermanCalculator />} />
      <Route path="/pol" element={<PolandCalculator />} />
      <Route path="/swiz" element={<SwissCalculator />} />
      <Route path="/dutch" element={<DutchCalculator />} />
      <Route path="/polandpayroll" element={<PolandPDF />} />
      <Route path="/ukpayroll" element={<PamgroPDF />} />
      <Route path="/czechpayroll" element={<Czechpdf />} />
      <Route path="/swisspayroll" element={<SwissPDF />} />
      <Route path="/australiapayroll" element={<AustraliaPDF />} />
    </Routes>
  );
};

export default CalculatorRoutes;
