import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const SalaryDistributionPie = ({ data, ismobile }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myPieChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          "Apprenticeship levy",
          "Insurance",
          "Pamgro fees",
          "Pensions",
          "Tax",
          "Take-home salary",
        ],
        datasets: [
          {
            data: data,
            backgroundColor: [
              "#FACC15",
              "#9063F1",
              "#F59E0B",
              "#EC4899",
              "#5A94F2",
              "#14B8A6",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: ismobile ? "right" : "bottom", // Set legend position to 'right'
          },
        },
        maintainAspectRatio: false,
        responsive: false,
        aspectRatio: ismobile ? 1 : 2,
      },
    });

    // Ensure the previous chart instance is destroyed on unmount
    return () => {
      myPieChart.destroy();
    };
  }, [data, ismobile]);

  return (
    <canvas
      ref={chartRef}
      style={{
        height: "52vh",
        width: "41vh",
        transform: ismobile ? "scale(1.6)" : "scale(3.5)",
      }}
    />
  );
};

export default SalaryDistributionPie;
