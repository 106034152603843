import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const CzechPieChart = ({ data, ismobile }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myPieChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          "OASI (AHV/IV/EO)",
          "UI (ALV)",
          "UI (ALV 2)",
          "NOAI (NBU)",
          "Pension (BVG)",
          "Net Salary",
        ],
        datasets: [
          {
            data: data,
            backgroundColor: [
              "#FACC15",
              "#9063F1",
              "#F59E0B",
              "#3652AD",
              "#99BC85",
              "#5A94F2",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: ismobile ? "right" : "bottom",
          },
        },

        maintainAspectRatio: false,
        responsive: false,
        aspectRatio: ismobile ? 1 : 2,
      },
    });

    return () => {
      myPieChart.destroy();
    };
  }, [data, ismobile]);

  return (
    <canvas
      ref={chartRef}
      style={{
        height: "52vh",
        width: "41vh",
        transform: ismobile ? "scale(1.6)" : "scale(3.5)",
      }}
    />
  );
};

export default CzechPieChart;
