import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Stack,
  Snackbar,
} from "@mui/material";
import validator from "validator";
import axios from "axios";
import Item from "./Item";
import StepperHorizontal from "../src/images/StepperHorizontal.svg";
import step1 from "../src/images/step1.svg";
import PamgroEmailContent from "./PamgroEmailContent";
import MuiAlert from "@mui/material/Alert";

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  dailyRate: string;
}

const CzechCalculator: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phoneNumber: "",
    dailyRate: "",
  });

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "success">(
    "success"
  );
  const openSnackbar = (message: string, severity: "error" | "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "email") {
      setIsEmailValid(validator.isEmail(value));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (formData.name && formData.email && formData.dailyRate) {
      try {
        const dataToSend = {
          candidateName: formData.name,
          candidateEmail: formData.email,
          candidateNameMobileNo: formData.phoneNumber,
          dailyRate: formData.dailyRate,
          calculatorReigon: "Czech",
        };

        const emailBodyContent = renderToString(
          <PamgroEmailContent
            pdfDownloadLink={""}
            candidateName={formData.name}
            url={`https://calc.pamgro.com/czechpayroll?dailypayrate=${formData.dailyRate}`}
          />
        );

        const emailData = {
          candidateName: formData.name,
          candidateNameMobileNo: formData.phoneNumber,
          candidateEmail: formData.email,
          dailyRate: formData.dailyRate,
          attachmentUrl: "",
          emailBodyContent: emailBodyContent,
          calculatorReigon: "Czech",
        };
        try {
          const sendEmailRes = await axios.post(
            "https://backend.pamgro.com/api/send-email",
            emailData
          );
          const response = await axios.post(
            "https://backend.pamgro.com/api/saveLead",
            dataToSend
          );

          if (sendEmailRes.status === 200 && response.status === 201) {
            setIsDialogOpen(true);

            // @ts-ignore
            window.dataLayer.push({
              event: "czechCalculatorSubmitClick",
              eventCategory: "Payroll Details",
              eventAction: "Submit Button Clicked",
              eventLabel: "Get Payroll Details Czech",
            });
            openSnackbar(
              "Please check your email box. We have sent your Payroll Details.",
              "success"
            );
            e.preventDefault();
            window.parent.location.href = "https://pamgro.com/thank-you-czech/";
          } else {
            setIsErrorDialogOpen(true);
          }
        } catch (error) {
          console.log("Error during the process: ", error);
          window.parent.location.href = "https://pamgro.com/thank-you-czech/";
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    } else {
      setIsErrorDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsErrorDialogOpen(false);
  };

  const handleUnlockTruePayrollClick = () => {
    if (!formData.dailyRate || formData.dailyRate === "0") {
      setIsErrorDialogOpen(true);
    } else {
      setCurrentStep(2);
    }
  };

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const width = window.innerWidth;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff00",
        height: "50vh",
        paddingLeft: "19px",
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          mt={12}
          xs={12}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-4vh",
          }}
        >
          {currentStep === 1 && (
            <Item>
              <Grid container>
                {!isMobileDevice() ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ marginTop: "-3vh" }}
                    >
                      <Item sx={{ padding: isMobileDevice() ? 0 : 2 }}>
                        <Grid item xs={4} sm={8}>
                          <Typography
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              fontStyle: "normal",
                              fontFamily: "'Gilroy Medium', sans-serif",
                              fontSize: "24px",
                              textAlign: "start",
                              marginLeft: "1vh",
                              marginTop: "1vh",
                            }}
                          >
                            Add pay details
                          </Typography>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ flexBasis: "10.2%" }}
                    >
                      <img
                        src={step1}
                        alt="step 1"
                        style={{ height: "40px", width: "430px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Stack>
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "'Gilroy Medium', sans-serif",
                        fontSize: "20px",
                        textAlign: "start",
                        marginLeft: "1vh",
                        marginTop: "3vh",
                        padding: "0px",
                      }}
                    >
                      Add pay details
                    </Typography>
                    <img
                      src={step1}
                      alt="step 1"
                      style={{
                        height: "40px",
                        width: "150px",
                        marginLeft: "20vh",
                      }}
                    />
                  </Stack>
                )}
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={4.5}
                  sm={11}
                  md={10}
                  lg={10}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderBottom: "1px solid #EFEFEF",
                    marginLeft: "1vh",
                    marginTop: "1vh",
                    height: "0px",
                  }}
                ></Grid>
                <Item>
                  <Grid
                    container
                    item
                    xs={5}
                    sm={12}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      marginTop: "-2vh",
                      justifyContent: "center",
                      width: "702px",
                      height: "267px",
                      paddingTop: "8vh",
                      paddingBottom: "5vh",
                      marginLeft: "-2.8vh",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid item xs={9} sm={10.5}>
                      {" "}
                      <InputLabel>Daily pay Rate (CZK) * </InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          type="number"
                          value={
                            formData.dailyRate === "0" ? "" : formData.dailyRate
                          }
                          onChange={handleInputChange}
                          name="dailyRate"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Item>

                <Grid
                  container
                  item
                  xs={12}
                  sm={11}
                  sx={{ flexBasis: "40.2%" }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      height: "60px",
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
                      marginTop: "-3vh",
                      backgroundColor: "#EE4037",
                      borderRadius: "6px",
                      color: "#ffffff",
                      marginLeft: "3vh",
                      fontWeight: "700",
                    }}
                    onClick={handleUnlockTruePayrollClick}
                  >
                    Unlock True Payroll
                  </Button>
                </Grid>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  severity={snackbarSeverity}
                  onClose={() => setSnackbarOpen(false)}
                  style={{
                    backgroundColor:
                      snackbarSeverity === "error" ? "red" : "green",
                    color: "white",
                  }}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
              </Grid>
            </Item>
          )}
          {currentStep === 2 && (
            <Item>
              <Grid container>
                {!isMobileDevice() ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ marginTop: "-3vh" }}
                    >
                      <Item sx={{ padding: isMobileDevice() ? 0 : 2 }}>
                        <Grid item xs={4} sm={8}>
                          <Typography
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              fontStyle: "normal",
                              fontFamily: "'Gilroy Medium', sans-serif",
                              fontSize: "24px",
                              textAlign: "start",
                              marginLeft: "1vh",
                              marginTop: "1vh",
                            }}
                          >
                            Add pay details
                          </Typography>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{ flexBasis: "10.2%" }}
                    >
                      <img
                        src={step1}
                        alt="step 1"
                        style={{ height: "40px", width: "430px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Stack>
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "'Gilroy Medium', sans-serif",
                        fontSize: "20px",
                        textAlign: "start",
                        marginLeft: "1vh",
                        marginTop: "3vh",
                        padding: "0px",
                      }}
                    >
                      Add Your details
                    </Typography>
                    <img
                      src={StepperHorizontal}
                      alt="step 1"
                      style={{
                        height: "40px",
                        width: "150px",
                        marginLeft: "20vh",
                      }}
                    />
                  </Stack>
                )}
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={4.5}
                  sm={11}
                  md={10}
                  lg={10}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderBottom: "1px solid #EFEFEF",
                    marginLeft: "1vh",
                    marginTop: "1vh",
                    height: "0px",
                  }}
                ></Grid>
                <Item>
                  <Grid
                    container
                    item
                    xs={5}
                    sm={12}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      marginTop: "-2vh",
                      justifyContent: "center",
                      width: "702px",
                      height: "267px",
                      paddingTop: "8vh",
                      paddingBottom: "5vh",
                      marginLeft: "-2.8vh",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid item xs={9} sm={10.5}>
                      <InputLabel>Name *</InputLabel>
                      <TextField
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                            marginBottom: "1rem",
                            height: "55px",
                          },
                        }}
                        fullWidth
                        label=""
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        style={{ marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={4.5} sm={5.3}>
                        <InputLabel>Email *</InputLabel>
                        <TextField
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "55px",
                            },
                          }}
                          fullWidth
                          label="Email (Required)"
                          value={formData.email}
                          onChange={handleInputChange}
                          name="email"
                          style={{ marginBottom: "1rem" }}
                          error={!isEmailValid}
                          helperText={
                            !isEmailValid ? "Invalid email format" : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={4.5} sm={5.3}>
                        <InputLabel>Phone</InputLabel>
                        <TextField
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              height: "55px",
                            },
                          }}
                          fullWidth
                          label="Phone Number"
                          type="tel"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          name="phoneNumber"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            maxLength: 9,
                          }}
                          style={{ marginBottom: "3rem" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid container item xs={5} sm={11} sx={{ flexBasis: "39%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    height: "60px",
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
                    marginTop: "-3vh",
                    backgroundColor: "#EE4037",
                    borderRadius: "6px",
                    color: "#ffffff",
                    marginLeft: "3vh",
                    fontWeight: "700",
                  }}
                  onClick={handleSubmit}
                >
                  Calculate Take-home
                </Button>
              </Grid>
            </Item>
          )}
        </Grid>

        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          style={{ borderRadius: "8" }}
        >
          <DialogTitle
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
            }}
          >
            Success
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
              }}
            >
              Successfully submitted !! You will receive the details shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleDialogClose}
              color="primary"
              autoFocus
              style={{
                backgroundColor: "black",
                color: "white",
                textTransform: "none",
                fontSize: "1.2rem",
                padding: "10px 20px",
                fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
                borderRadius: "5px",
              }}
            >
              Great!
            </Button>
          </DialogActions>
        </Dialog>
        {/*Alert Dialog*/}
        <Dialog
          open={isErrorDialogOpen}
          onClose={handleDialogClose}
          style={{ borderRadius: "8" }}
        >
          <DialogTitle
            style={{
              color: "red",
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
            }}
          >
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
              }}
            >
              Please fill in all mandatory Details
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleDialogClose}
              color="primary"
              autoFocus
              style={{
                backgroundColor: "red",
                color: "white",
                textTransform: "none",
                fontSize: "1.2rem",
                padding: "10px 20px",
                borderRadius: "5px",
                fontFamily: '"Spartan", Arial, Helvetica, sans-serif',
              }}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default CzechCalculator;
