import React from "react";

interface EmailFrameProps {
  pdfDownloadLink: string;
  candidateName: string;
  url?: string;
}

const EmailFrame: React.FC<EmailFrameProps> = ({
  pdfDownloadLink,
  candidateName,
  url,
}) => {
  return (
    <table
      align="center"
      cellPadding="0"
      cellSpacing="0"
      border={0}
      width="100%"
      style={{ fontFamily: '"Inter", Helvetica', fontSize: "14px" }}
    >
      {/* Header Section */}
      <tr>
        <td>
          <table
            align="center"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            width="600"
          >
            <tr>
              <td>
                <img
                  style={{
                    display: "block",
                    margin: "20px auto",
                    width: "150px",
                  }}
                  alt="pamgro"
                  src="https://c.animaapp.com/Sdg3yJgl/img/image-25@2x.png"
                />
              </td>
            </tr>
            <tr>
              <td align="center">
                <p style={{ color: "#222222", margin: "0" }}>
                  Enjoy Compliant & Cost Effective Payroll Solution
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      {/* Main Content Section */}
      <tr>
        <td>
          <table
            align="center"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            width="600"
          >
            {/* Background Image Section */}
            <tr>
              <td>
                <div
                  style={{
                    backgroundImage:
                      "url(https://c.animaapp.com/Sdg3yJgl/img/rectangle-29.png)",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <p
                    style={{
                      color: "#141414",
                      margin: "40px 0 20px 0",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold-☞, Helvetica",
                      fontSize: "19px",
                    }}
                  >
                    Your Payroll Calculation is Ready 🎉
                  </p>

                  {/* Content Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "11px",
                      height: "63px",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        alignSelf: "stretch",
                        color: "#222222",
                        fontFamily: "Inter, Helvetica",
                        fontSize: "15px",
                        fontWeight: "400",
                        letterSpacing: "0",
                        lineHeight: "25.5px",
                        margin: "0",
                      }}
                    >
                      <span>Dear {candidateName}, </span>
                    </p>
                  </div>

                  {/* Additional Content Section */}
                  <table
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    border={0}
                    width="100%"
                    style={{
                      gap: "11px",
                      fontFamily: '"Inter", Helvetica',
                      fontSize: "14px",
                    }}
                  >
                    <tr>
                      <td>
                        {/* Additional Content Section */}
                        <table
                          align="center"
                          cellPadding="0"
                          cellSpacing="0"
                          border={0}
                          width="600"
                        >
                          <tr>
                            <td>
                              <p
                                style={{
                                  alignSelf: "stretch",
                                  color: "#222222",
                                  fontFamily: "Inter, Helvetica",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  letterSpacing: "0",
                                  lineHeight: "25.5px",
                                  margin: "0",
                                }}
                              >
                                <span>
                                  Thank you for using our PamGro Payroll
                                  Calculator!{" "}
                                </span>
                              </p>
                              <br />
                              <p
                                style={{
                                  alignSelf: "stretch",
                                  color: "#222222",
                                  fontFamily: "Inter, Helvetica",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  letterSpacing: "0",
                                  lineHeight: "25.5px",
                                  margin: "0",
                                }}
                              >
                                <span>
                                  Did you know that smart payroll management can
                                  save you from-
                                  <br />
                                </span>
                              </p>
                              <ul
                                style={{
                                  alignSelf: "stretch",
                                  color: "#222222",
                                  fontFamily: "Inter, Helvetica",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  letterSpacing: "0",
                                  lineHeight: "25.5px",
                                  margin: "0",
                                  paddingLeft: "20px",
                                }}
                              >
                                <li>A lot of headache</li>
                                <li>And hours of time.</li>
                              </ul>
                              <p
                                style={{
                                  alignSelf: "stretch",
                                  color: "#222222",
                                  fontFamily: "Inter, Helvetica",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  letterSpacing: "0",
                                  lineHeight: "25.5px",
                                  margin: "0",
                                }}
                              >
                                <span>
                                  Let's make every second count! We've crunched
                                  the numbers and your detailed payroll is ready
                                  to access 👇{" "}
                                </span>
                              </p>
                              <br />
                              {/* Button Section */}
                              <tr>
                                <td align="center">
                                  <a
                                    href={url ? url : pdfDownloadLink}
                                    download="your-file-name.pdf"
                                    style={{
                                      backgroundColor: "#EE4037",
                                      color: "white",
                                      textTransform: "none",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontFamily: "Inter, Helvetica",
                                      fontStyle: "normal",
                                      fontWeight: "700",
                                      lineHeight: "130%",
                                      padding: "10px 20px",
                                      borderRadius: "5px",
                                      margin: "auto",
                                      width: "200px",
                                      textDecoration: "none",
                                      display: "flex",
                                    }}
                                  >
                                    <span style={{ margin: "auto" }}>
                                      View Payroll
                                    </span>{" "}
                                  </a>
                                </td>
                              </tr>
                              <br />

                              {/* Footer Section */}
                              <tr>
                                <td>
                                  <p
                                    style={{
                                      alignSelf: "stretch",
                                      color: "#6d6d6d",
                                      fontFamily: "Inter, Helvetica",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      letterSpacing: "0",
                                      lineHeight: "18.5px",
                                      margin: "5px",
                                    }}
                                  >
                                    <span>
                                      For further communication, a relationship
                                      manager will contact you within the next
                                      24 hours.{" "}
                                    </span>
                                  </p>

                                  {/* More Content Section */}
                                  <p
                                    style={{
                                      color: "#222222",
                                      alignSelf: "stretch",
                                      fontFamily: "Inter, Helvetica",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      letterSpacing: "0",
                                    }}
                                  >
                                    There’s more for you ✌
                                  </p>
                                  <tr>
                                    <td>
                                      <div style={{ margin: "20px" }}>
                                        <p
                                          style={{
                                            color: "#222222",
                                            alignSelf: "stretch",
                                            fontFamily: "Inter, Helvetica",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                            letterSpacing: "0",
                                          }}
                                        >
                                          Book a 15 min. demo to understand
                                          document or learn tactics on improving
                                          net home takeaway.
                                        </p>
                                        <a
                                          href="https://calendly.com/free_netpay_consultation/?utm_source=email&utm_medium=organic_net_pay_calculator_form&utm_campaign=payroll_calculator_form_fillings"
                                          style={{
                                            color: "#EE4037",
                                            fontFamily: "Inter, Helvetica",
                                            fontSize: "15px",
                                            fontWeight: "700",
                                            letterSpacing: "0",
                                            lineHeight: "25.5px",
                                            textDecoration: "none",
                                          }}
                                        >
                                          Book a demo now!
                                        </a>
                                        <p
                                          style={{
                                            color: "#141414",
                                            fontFamily:
                                              "Gilroy-SemiBold-☞, Helvetica",
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            letterSpacing: "0",
                                          }}
                                        >
                                          Looking for a job?
                                        </p>
                                        <a
                                          href="https://jobs.avanceservices.com/?utm_source=contractor_netpay&utm_medium=email&utm_campaign=pamgro_payroll+calculator"
                                          style={{
                                            color: "#EE4037",
                                            fontFamily: "Inter, Helvetica",
                                            fontSize: "15px",
                                            fontWeight: "700",
                                            letterSpacing: "0",
                                            lineHeight: "25.5px",
                                            textDecoration: "none",
                                          }}
                                        >
                                          Find jobs
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      {/* Footer Section */}
    </table>
  );
};

export default EmailFrame;
